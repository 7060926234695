import actions from './product-actions'
import getters from './product-getters'
import mutations from './product-mutations'
import state from './product-state'

/**
 * @module store/product
 */

export default {
	namespaced: true,
	actions,
	getters,
	mutations,
	state,
}
