export default {
	stripeSessionId(state) {
		return state.stripe.sessionId
	},
	stripeOrderItems(state) {
		return state.stripe.orderItems
	},
	isOrderLoading(state) {
		return state.isOrderLoading
	},
}
