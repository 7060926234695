import axios from '@/plugins/axios'

export default (categoryName) => {
	const url = '/api/v1/category'

	let config = {
		headers: {},
		params: { name: categoryName },
	}

	return axios.get(url, config)
}
