import * as types from '../mutation-types'
import ApiRequest from '@/services/services'
import { loadStripe } from '@stripe/stripe-js'

export default {
	async getStripeResponse({ commit }, payload) {
		commit(types.IS_ORDER_LOADING, true)

		const stripe = await loadStripe(
			'pk_test_51PNd8S08cN3hEVOXgYc9GBu9Qsc3TtWSff11F4PHL0MAEXy9RW6yJ6VIoRhskcne0ASYbmcB8nUfUw0jcvihfI1n00jWUkcazf'
		)
		// 		const stripe = await loadStripe(`${process.env.STRIPE_PUBLIC_KEY}`)

		ApiRequest.stripe
			.redirectToCheckout(payload)
			.then((response) => {
				commit(types.CHECKOUT_SESSION_ID, response.data?.id)
				stripe.redirectToCheckout({
					sessionId: response.data?.id,
				})
				commit(types.IS_ORDER_LOADING, false)
			})
			.catch((error) => {
				console.log(error)
			})
	},

	getOrderItems({ commit }, sessionId) {
		commit(types.IS_ORDER_LOADING, true)
		ApiRequest.stripe
			.getOrderItems(sessionId)
			.then((response) => {
				commit(types.ORDER_DETAILS, response.data)
				commit(types.IS_ORDER_LOADING, false)
			})
			.catch((error) => {
				console.log(error)
			})
	},
}
