import * as types from '../mutation-types'
import ApiRequest from '@/services/services'

/**
 * @module store/product/actions
 */

export default {
	getProduct({ commit, dispatch }, payload) {
		dispatch('common/isLoading', true, { root: true })

		ApiRequest.product
			.getProduct(payload)
			.then((response) => {
				commit(types.PRODUCT_RESPONSE, response.data.data)
				dispatch('common/isLoading', false, { root: true })
			})
			.catch((error) => {
				console.log(error)
			})
	},

	getFeaturedProduct({ commit, dispatch }) {
		dispatch('common/isLoading', true, { root: true })

		ApiRequest.product
			.getFeaturedProducts()
			.then((response) => {
				console.log(response)
				commit(types.PRODUCT_RESPONSE, response.data.data)
				dispatch('common/isLoading', false, { root: true })
			})
			.catch((error) => {
				console.log(error)
			})
	},
}
