import * as types from '../mutation-types'
import Cookies from 'js-cookie'

export default {
	addItemToCart({ commit, state }, product) {
		commit(types.CART_ITEMS, product)
		// Update cookies whenever the cart items change
		Cookies.set('cartItems', JSON.stringify(state.cart.items), { expires: 7 }) // Expires in 7 days
	},

	initializeCart({ commit }) {
		const cartItems = Cookies.get('cartItems')
		if (cartItems) {
			commit(types.SET_CART_ITEMS, JSON.parse(cartItems))
		}
	},
}
