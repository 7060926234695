export default [
	{
		path: '/',
		name: 'home',
		component: () =>
			import(/* webpackChunkName: "home" */ '@/views/home/home-index'),
		meta: {
			title: 'NDIS Supply Hub',
		},
	},
	{
		path: '/category/:category',
		name: 'category',
		component: () =>
			import(
				/* webpackChunkName: "categoryDetail" */ '@/views/category/category-index'
			),
		meta: { title: 'Category' },
	},
	{
		path: '/category/:category/:uuid',
		name: 'product',
		component: () =>
			import(/* webpackChunkName: "product" */ '@/views/product/product-index'),
		meta: {
			title: 'Product',
		},
	},
	{
		path: '/checkout-success',
		name: 'checkout-success',
		component: () =>
			import(
				/* webpackChunkName: "checkout-success" */ '@/views/checkout/checkout-success'
			),
		props: (route) => ({ sessionId: route.query.session_id }),
	},
	{
		path: '/checkout-cancelled',
		name: 'checkout-cancelled',
		component: () =>
			import(
				/* webpackChunkName: "checkout-cancelled" */ '@/views/checkout/checkout-cancelled'
			),
	},
]
