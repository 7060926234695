import Vue from 'vue'
import Vuex from 'vuex'
import categoryModule from './category/category-module'
import productModule from './product/product-module'
import checkoutModule from './checkout/checkout-module'
import commonModule from './common/common-module'
import cartModule from './cart/cart-module'

Vue.use(Vuex)

/**
 * Modules part of this store.
 */
const modules = {
	common: commonModule,
	cart: cartModule,
	category: categoryModule,
	product: productModule,
	checkout: checkoutModule,
}

export const storeConfig = {
	modules,
	strict: false,
	plugins: [],
}
const store = new Vuex.Store(storeConfig)

// Initialize cart from cookies when store is created
store.dispatch('cart/initializeCart')

export default store
