import axios from '@/plugins/axios'

export default (sessionId) => {
	const url = '/api/v1/stripe/get-session'

	let config = {
		headers: {},
		params: { session_id: sessionId },
	}

	return axios.get(url, config)
}
