import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/routes'
import { debounce } from 'lodash'

Vue.use(VueRouter)

export const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

const smoothScrollToTop = debounce(() => {
	window.scrollTo({
		top: 0,
		behavior: 'smooth',
	})
}, 200)

router.beforeEach((toRoute, fromRoute, next) => {
	window.document.title =
		toRoute.meta && toRoute.meta.title
			? toRoute.meta.title + ' | NDIS Service Hub'
			: 'NDIS Service Hub'

	if (fromRoute.name) {
		smoothScrollToTop()
	}

	next()
})

Vue.router = router

export default {
	router,
}
