/**
 * @module store/mutation-types
 */

/**
 * Category Module
 */
export const CATEGORY_RESPONSE = 'CATEGORY_RESPONSE'

/**
 * Product Module
 */
export const PRODUCT_RESPONSE = 'PRODUCT_RESPONSE'

/**
 * Checkout Module
 */
export const CHECKOUT_SESSION_ID = 'CHECKOUT_SESSION_ID'
export const ORDER_DETAILS = 'ORDER_DETAILS'
export const IS_ORDER_LOADING = 'IS_ORDER_LOADING'

/**
 * Common Module
 */
export const IS_LOADING = 'IS_LOADING'

/**
 * Carts Module
 */
export const CART_ITEMS = 'CART_ITEMS'
export const CART_SUBTOTAL = 'CART_SUBTOTAL'
export const SET_CART_ITEMS = 'SET_CART_ITEMS'
