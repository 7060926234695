import Vue from 'vue'
import axios from 'axios'

Vue.$http = axios

axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL

axios.interceptors.request.use(
	(config) => {
		// Add more config here as we go
		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

export default axios
