import Vue from 'vue'
import App from './App.vue'
import store from '@/store/store'
import { router } from '@/plugins/vue-router'

// Plugins
import '@/plugins/vue-router'
import '@/plugins/bootstrap-vue'
import '@/plugins/axios'
import '@/plugins/font-awesome'
import '@/plugins/vue-slick-carousel'
import '@/plugins/vue-navigation-bar'
import '@/plugins/lodash'

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: function (h) {
		return h(App)
	},
}).$mount('#app')
