import axios from '@/plugins/axios'

export default () => {
	// Can add category to params to get featured product by main category
	const url = '/api/v1/product/featured'

	let config = {
		headers: {},
		params: {},
	}

	return axios.get(url, config)
}
