import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'

//Importing Brand Specific Icons
import {
	faInstagram,
	faFacebook,
	faGoogle,
	faTwitter,
	faLinkedin,
} from '@fortawesome/free-brands-svg-icons'

//Import solid icons
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
	faBasketShopping,
	faTruckMoving,
} from '@fortawesome/free-solid-svg-icons'

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
library.add(
	faBasketShopping,
	faInstagram,
	faGoogle,
	faFacebook,
	faTwitter,
	faInstagram,
	faTwitter,
	faLinkedin,
	faTruckMoving
)
