import getCategory from './category/getCategoryByName'
import getProductByUuid from './product/getProductByUuid'
import getFeaturedProducts from './product/getFeaturedProducts'
import redirectToCheckout from './stripe/redirectToCheckout'
import getOrderItems from './stripe/getOrderDetails'
import getFeaturedCategories from './category/getFeaturedCategories'

export default {
	category: {
		getCategory: getCategory,
		getFeaturedCategories: getFeaturedCategories,
	},
	product: {
		getProduct: getProductByUuid,
		getFeaturedProducts: getFeaturedProducts,
	},
	stripe: {
		redirectToCheckout: redirectToCheckout,
		getOrderItems: getOrderItems,
	},
}
