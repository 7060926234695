import * as types from '../mutation-types'

export default {
	[types.CHECKOUT_SESSION_ID](state, sessionId) {
		state.stripe.sessionId = sessionId
	},
	[types.ORDER_DETAILS](state, response) {
		state.stripe.orderItems = response
	},
	[types.IS_ORDER_LOADING](state, isLoading) {
		state.isOrderLoading = isLoading
	},
}
