import axios from '@/plugins/axios'

export default (payload) => {
	const url = '/api/v1/stripe/create-checkout-session'

	let config = {
		headers: {},
		params: {},
	}

	return axios.post(url, payload, config)
}
