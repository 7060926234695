import actions from './category-actions'
import getters from './category-getters'
import mutations from './category-mutations'
import state from './category-state'

/**
 * @module store/category
 */

export default {
	namespaced: true,
	actions,
	getters,
	mutations,
	state: state,
}
