import * as types from '../mutation-types'

export default {
	[types.CART_ITEMS](state, product) {
		const existingProduct = state.cart.items.find(
			(item) => item.uuid === product.uuid
		)

		if (existingProduct) {
			existingProduct.quantity += product.quantity
		} else {
			state.cart.items.push(product)
		}
	},
	// New mutation to set cart items from cookies
	[types.SET_CART_ITEMS](state, items) {
		state.cart.items = items
	},
}
