import * as types from '../mutation-types'

/**
 * @module store/products/mutations
 */

export default {
	[types.PRODUCT_RESPONSE](state, response) {
		state.product.response = response
	},
}
