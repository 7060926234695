import axios from '@/plugins/axios'

export default (uuid) => {
	const url = '/api/v1/product'

	let config = {
		headers: {},
		params: { uuid: uuid },
	}

	return axios.get(url, config)
}
