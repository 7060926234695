import * as types from '../mutation-types'
import SweetAlert from '@/plugins/sweet-alert'
import { get, isEmpty, capitalize } from 'lodash'

/**
 * @module store/common/common-actions
 */

export default {
	/* eslint-disable no-empty-pattern */
	displayToastAlert({}, alertData) {
		if (SweetAlert.isVisible()) {
			SweetAlert.close()
		}
		SweetAlert.fire({
			toast: true,
			position: get(alertData, 'position', 'top-end'),
			showConfirmButton: false,
			showCloseButton: true,
			icon: get(alertData, 'icon', 'success'),
			title: get(alertData, 'title', 'Success!'),
			html: get(alertData, 'subtitle', ''),
			timer: 5000,
			timerProgressBar: true,
			didOpen: (toast) => {
				toast.addEventListener('mouseenter', SweetAlert.stopTimer)
				toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
			},
		})
	},

	displayErrorMessage({}, errorData) {
		// deconstruct errorData
		const data = get(errorData, 'data', {})
		const title = capitalize(
			get(errorData, 'title', "Oops, something's not right")
		)
		const message = get(
			errorData,
			'message',
			'If this continues, please contact us..'
		)

		let content = ''

		// get error message or error data
		if (isEmpty(data)) {
			// display error message if there's no error data
			content += `<div>${message}</div>`
		} else {
			content += `<div>${data.error}</div>`

			// Refactor this in the future
			// combine all error data
			// forOwn(data, function (item) {
			// 	forEach(item, function (message) {
			// 		content += `<div>${message}</div>`
			// 	})
			// })
		}

		// display the error modal
		SweetAlert.fire({
			confirmButtonText: 'Continue',
			title: `<div class="md-body-2 mb-2"><strong>${title}</strong></div>`,
			html: `<div class="p-1">${content}</div>`,
			heightAuto: false,
			scrollbarPadding: false,
		})
	},

	isLoading({ commit }, loading) {
		commit(types.IS_LOADING, loading)
	},
}
