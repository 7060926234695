import * as types from '../mutation-types'
/**
 * @module store/common/common-mutations
 */

export default {
	[types.IS_LOADING](state, isLoading) {
		state.loading = isLoading
	},
}
