import * as types from '@/store/mutation-types'
import ApiRequest from '@/services/services'

/**
 * @module store/category/category-actions
 */

export default {
	getCategory({ commit, dispatch }, payload) {
		dispatch('common/isLoading', true, { root: true })

		ApiRequest.category
			.getCategory(payload)
			.then((response) => {
				commit(types.CATEGORY_RESPONSE, response.data.data)
				dispatch('common/isLoading', false, { root: true })
			})
			.catch((error) => {
				console.log(error)
			})
	},

	getFeaturedCategories({ commit, dispatch }) {
		dispatch('common/isLoading', true, { root: true })

		ApiRequest.category
			.getFeaturedCategories()
			.then((response) => {
				commit(types.CATEGORY_RESPONSE, response.data.data)
				dispatch('common/isLoading', false, { root: true })
			})
			.catch((error) => {
				console.log(error)
			})
	},
}
