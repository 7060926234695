import actions from './checkout-actions'
import getters from './checkout-getters'
import mutations from './checkout-mutations'
import state from './checkout-state'

/**
 * @module store/checkout
 */

export default {
	namespaced: true,
	actions,
	getters,
	mutations,
	state,
}
